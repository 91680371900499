import React from 'react';
import { Link } from 'gatsby';
import { instanceOf, string } from 'prop-types';
import { Grid } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { normalizePath } from '../../../utils/get-url-path';

function ArticleSwitchButton({ uri, title, direction }) {
  const justify = direction.toLowerCase() === 'next' ? 'flex-end' : 'flex-start';
  const itemClassName = direction.toLowerCase() === 'next' ? 'borderRight' : 'borderLeft';
  const directionClassName = direction.toLowerCase() === 'next' ? 'directionRight' : 'directionLeft';
  const directionText = direction.toLowerCase() === 'next' ? (
    <>
      NEXT POST
      <ChevronRight />
    </>
  ) : (
    <>
      <ChevronLeft />
      {' '}
      PREV POST
    </>
  );
  return (
    <Grid item xs={12} sm={6}>
      <Link
        className={`pageLink ${itemClassName}`}
        to={normalizePath(uri)}
      >
        <Grid container justify={justify}>
          <span className={`direction ${directionClassName}`}>{directionText}</span>
          <span className="switchButtonTitle">{title}</span>
        </Grid>
      </Link>
    </Grid>
  );
}

function RenderArticleSwitchButtons({ nextPage, previousPage }) {
  return (
    <Grid className="switchButtonContainer" container justify="flex-start" alignItems="flex-start">
      {previousPage ? (
        <ArticleSwitchButton uri={previousPage.uri} title={previousPage.title} direction="Previous" />
      )
        : <Grid item xs={12} sm={6}> </Grid>}
      {nextPage ? (
        <ArticleSwitchButton uri={nextPage.uri} title={nextPage.title} direction="Next" />
      )
        : <Grid item xs={12} sm={6}> </Grid>}
    </Grid>
  );
}

RenderArticleSwitchButtons.propTypes = {
  nextPage: instanceOf(Object).isRequired,
  previousPage: instanceOf(Object).isRequired,
};

ArticleSwitchButton.propTypes = {
  uri: string.isRequired,
  title: string.isRequired,
  direction: string.isRequired,
};

export default RenderArticleSwitchButtons;
