import React from 'react';
import { instanceOf } from 'prop-types';
import { Grid } from '@material-ui/core';
import Layout from '../../layout';
import {
  ArticleContent,
  ArticleTitle,
  RenderArticleSwitchButtons,
  ArticleDetails,
} from './index';
import SideBar from '../../NewsBar';
import setPageTitleAndFavicon from '../../../utils/setPageTitleAndFavicon';
import PowerInbox from './PowerInbox';

function Article({ data }) {
  const { nextPage, previousPage, page } = data;
  const {
    title, content, author, date, categories,
  } = page;
  setPageTitleAndFavicon(title);
  return (
    <Layout>
      <Grid container justify="flex-start" alignItems="flex-start" className="articleContainer">
        <div id="back-to-top-anchor" />
        <Grid item xs={12} sm={9} className="article" >
          <ArticleTitle title={title} category={categories ? categories.nodes[0].name : ''} />
          <ArticleDetails author={author} date={date} />
          <ArticleContent content={content} />
          <PowerInbox />
          <RenderArticleSwitchButtons nextPage={nextPage} previousPage={previousPage} />
        </Grid>
        <Grid xs={12} sm={3}>
          <SideBar postLimit={5} isArticlePage />
        </Grid>
      </Grid>
    </Layout>
  );
}

Article.propTypes = {
  data: instanceOf(Object).isRequired,
};

export default Article;
