import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { string } from 'prop-types';

function ArticleTitle({ title, category }) {
  return (
    <Grid container justify="flex-start" alignItems="flex-start">
      <div id="back-to-top-anchor" />
      <Grid item xs={12}>
        <div className="category">
          {category}
        </div>
      </Grid>
      <Grid item xs={12}>
          <h1 className="articleTitle">{title}</h1>
      </Grid>
    </Grid>
  );
}

ArticleTitle.propTypes = {
  category: string.isRequired,
  title: string.isRequired,
};

export default ArticleTitle;
