import React from 'react';
import Img from 'gatsby-image';
import { instanceOf } from 'prop-types';
import Box from '../Box';

function ArticleImage({ featuredImage }) {
  return featuredImage?.node?.localFile?.childImageSharp ? (
    <Box mb={5}>
      <Img
        className="articleImage"
        fluid={featuredImage.node.localFile.childImageSharp.fluid}
        loading="eager"
      />
    </Box>
  )
    : '';
}

ArticleImage.propTypes = {
  featuredImage: instanceOf(Object).isRequired,
};

export default ArticleImage;
