import React from 'react';
import { Grid } from '@material-ui/core';
import { element } from 'prop-types';

function ArticleContent({ content }) {
  return (
    <Grid className="articleContentContainer" container justify="flex-start" alignItems="flex-start">
      {/*<Grid item xs={12} sm={3} />*/}
      <Grid item xs={12} sm={12}>
        <div className="articleContent" dangerouslySetInnerHTML={{ __html: content }} />
      </Grid>
    </Grid>
  );
}

ArticleContent.propTypes = {
  content: element.isRequired,
};

export default ArticleContent;
