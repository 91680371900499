import React from 'react';
import { Grid } from '@material-ui/core';
import { WatchLaterOutlined } from '@material-ui/icons';
import { instanceOf, string } from 'prop-types';
import moment from 'moment';
import { Link } from 'gatsby';
import { normalizePath } from '../../../utils/get-url-path';

function ArticleDetails({ author, date }) {
  if (!date || !author) {
    return '';
  }
  const formattedDate = moment(new Date(date)).format('MMMM Do, YYYY');
  return (
    <Grid className="articleDetailsContainer" container alignItems="flex-start" justify="flex-start">
      <Grid item lg={2} md={3} sm={4} xs={6}>
        by
        <Link
          className="articleAuthor"
          to={normalizePath(author ? author.node.uri : '')}
        >
          {` ${author ? author.node.name : ''}`}
        </Link>
      </Grid>
      <Grid className="date" item lg={2} md={3} sm={4} xs={6}>
        <WatchLaterOutlined className="clock" />
        {formattedDate}
      </Grid>
    </Grid>
  );
}

ArticleDetails.propTypes = {
  author: instanceOf(Object).isRequired,
  date: string.isRequired,
};

export default ArticleDetails;
