import React from 'react';

export default function PowerInbox() {
  require('./powerinbox.css');
  return (
    <div className="pi_119713 powerinbox">
      <style type="text/css">

      </style>
      <div className="pi_header">
        <div className="pi_title">You Might Like</div>
      </div>
      <div className="pi_au_container">
        <div className="pi_au pi_o">
          <a
            href="https://stripe.rs-stripe.com/stripe/redirect?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=0&cs_esp=powerinbox_web&cs_containertype=web"
            target="_blank" rel="nofollow noopener"><img alt=""
                                                         src="https://stripe.rs-stripe.com/stripe/image?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=0&cs_esp=powerinbox_web&cs_containertype=web"/></a>
        </div>
        <div className="pi_au">
          <a
            href="https://stripe.rs-stripe.com/stripe/redirect?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=1&cs_esp=powerinbox_web&cs_containertype=web"
            target="_blank" rel="nofollow noopener"><img alt=""
                                                         src="https://stripe.rs-stripe.com/stripe/image?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=1&cs_esp=powerinbox_web&cs_containertype=web"/></a>
        </div>
        <div className="pi_au pi_o">
          <a
            href="https://stripe.rs-stripe.com/stripe/redirect?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=2&cs_esp=powerinbox_web&cs_containertype=web"
            target="_blank" rel="nofollow noopener"><img alt=""
                                                         src="https://stripe.rs-stripe.com/stripe/image?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=2&cs_esp=powerinbox_web&cs_containertype=web"/></a>
        </div>
        <div className="pi_au">
          <a
            href="https://stripe.rs-stripe.com/stripe/redirect?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=3&cs_esp=powerinbox_web&cs_containertype=web"
            target="_blank" rel="nofollow noopener"><img alt=""
                                                         src="https://stripe.rs-stripe.com/stripe/image?cs_email={{GUID}}&cs_stripeid=119713&cs_offset=3&cs_esp=powerinbox_web&cs_containertype=web"/></a>
        </div>
      </div>
      <div className="pi_footer">
        <div className="pi_brand">
          <a
            href="https://stripe.rs-stripe.com/branding/?utm_source=contentstripe&utm_campaign=rs_119713&utm_medium=web&utm_content=logo&rs_type=web"
            target="_blank" rel="nofollow noopener"><img
            src="https://stripe.rs-stripe.com/branding/recommend/short.png"
            alt="Learn more about RevenueStripe..."/></a>
        </div>
      </div>
    </div>
  );
}
